import { AxiosResponse } from "axios";
import APIEndpoints from "enums/APIEndpoints";
import useAxios from "hooks/useAxios";

export type MonitoringVehiclesReport = {
  id: string;
  type: string;
  plate: string;
  camera: string;
  image_link: string;
  equipment: string;
  description: string;
  ["date_capture"]: string;
  ["serial_number"]: string;
  latitude: string;
  longitude: string;
  ["ocr_total_time"]: number;
  ["plate_color"]: number;
  ["plate_coordinate"]: string;
};

export type MonitoringVehiclesReportPaginated = {
  registers: {
    items: MonitoringVehiclesReport[];
    page: number;
    ["total_items"]: number;
    ["total_pages"]: number;
  };
};

export type VehiclesMake = {
  name: string;
  isDivider: boolean;
};

type ListParams = {
  ["customer_id"]: string;
  page: number;
  plate?: string;
  description?: string;
  ["final_date"]: string;
  ["page_size"]?: number;
  ["initial_date"]: string;
  id?: string;
  user?: string;
  group?: string;
  cameras?: string[];
  cluster?: string;
  equipments?: string[];
  ["vehicle_make"]?: string | undefined;
  ["vehicle_model"]?: string | undefined;
  ["vehicle_color"]?: string | undefined;
};

type DownloadCsvParams = {
  ["customer_id"]: string;
  plate?: string;
  description?: string;
  equipments?: string[];
  ["final_date"]: string;
  ["initial_date"]: string;
  language: string;
  ["vehicle_make"]?: string | undefined;
  ["vehicle_model"]?: string | undefined;
  ["vehicle_color"]?: string | undefined;
};

type UseMonitoringVehiclesReportAPIType = {
  list: (filter: ListParams) => Promise<MonitoringVehiclesReportPaginated>;
  downloadCsv: (filter: DownloadCsvParams) => Promise<string>;
  downloadPdf: (filter: DownloadCsvParams) => Promise<string>;
};

const useMonitoringVehiclesReportAPI =
  (): UseMonitoringVehiclesReportAPIType => {
    const API = useAxios();

    const list = async (
      filter: ListParams
    ): Promise<MonitoringVehiclesReportPaginated> => {
      const newFilter = { ...filter };

      if (!newFilter.plate) delete newFilter.plate;

      if (!newFilter.description) delete newFilter.description;

      if (!newFilter.equipments || newFilter.equipments.length < 1) {
        delete newFilter.equipments;
      }

      const response: AxiosResponse<MonitoringVehiclesReportPaginated> =
        await API.instance.post(
          APIEndpoints.MONITORING_VEHICLES_REPORT,
          newFilter
        );

      return response.data;
    };

    const downloadCsv = async (filter: DownloadCsvParams): Promise<string> => {
      const response: AxiosResponse<string> = await API.instance.post(
        APIEndpoints.MONITORING_VEHICLES_REPORT_DOWNLOAD,
        { ...filter, type: "csv" },
        { responseType: "blob" }
      );

      return response.data;
    };

    const downloadPdf = async (filter: DownloadCsvParams): Promise<string> => {
      const response: AxiosResponse<string> = await API.instance.post(
        APIEndpoints.MONITORING_VEHICLES_REPORT_DOWNLOAD,
        { ...filter, type: "pdf" }
      );
      return response.data;
    };

    return {
      list,
      downloadCsv,
      downloadPdf
    };
  };

export default useMonitoringVehiclesReportAPI;
