import {
  Box,
  CircularProgress,
  Grid,
  styled,
  Checkbox,
  TextField,
  Typography,
  Autocomplete,
  FormControlLabel,
  Divider
} from "@mui/material";
import { AxiosError } from "axios";
import { orderBy } from "lodash";
import Button from "components/Button";
import useToolsAPI from "api/ToolsAPI";
import Drawer from "components/Drawer";
import FormLabel from "components/FormLabel";
import useLocationAPI from "api/LocationAPI";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import InputField from "components/InputField";
import { useLocale } from "contexts/LocaleContext";
import { Controller, useForm } from "react-hook-form";
import DatePickerField from "components/DatePickerField";
import TimePickerField from "components/TimePickerField";
import { FC, useCallback, useEffect, useState } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { VehicleColor, vehicleColors } from "utils/VehicleConsts";
import { endOfDay, setHours, setMinutes, startOfDay } from "date-fns";
import { VehiclesMake } from "api/MonitoredVehiclesDashboardAPI";

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(4)} ${theme.spacing(
    4
  )} ${theme.spacing(4)}`
}));

const Placeholder = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.light
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${theme.palette.grey["200"]}`,
  "& button": {
    marginLeft: theme.spacing(1)
  }
}));

export type FormDashboardFilter = {
  startDate: string | Date;
  endDate: string | Date;
  startTime: string | Date;
  endTime: string | Date;
  plate: string | undefined;
  equipments: string[] | undefined;
  monitoredReasons: string[] | undefined;
  vehicleClass: string[] | undefined;
  make?: string;
  model?: string;
  color?: string;
};

export type AutocompleteOptions = {
  value: string;
  label: string;
};

const defaultDate = new Date();

export const defaultValues: FormDashboardFilter = {
  startDate: startOfDay(defaultDate),
  endDate: endOfDay(defaultDate),
  startTime: startOfDay(defaultDate),
  endTime: endOfDay(defaultDate),
  plate: undefined,
  equipments: undefined,
  monitoredReasons: undefined,
  vehicleClass: undefined,
  make: undefined,
  model: undefined,
  color: undefined
};

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  setFilter: (filter: FormDashboardFilter) => void;
  makes: VehiclesMake[];
};

const MonitoredVehiclesDashboardFilter: FC<Props> = ({
  open,
  setOpen,
  setFilter,
  makes
}) => {
  const ToolsAPI = useToolsAPI();
  const LocationAPI = useLocationAPI();
  const { language } = useLocale();
  const { t } = useTranslation();
  const { sessionUser } = useAuth();
  const [monthCheckbox, setMonthCheckbox] = useState<boolean>(false);
  const [todayCheckbox, setTodayCheckbox] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { errorHandler } = useErrorHandler();
  const { control, formState, handleSubmit, reset, setValue, getValues } =
    useForm<FormDashboardFilter>({
      mode: "onChange",
      defaultValues
    });
  const [locations, setLocations] = useState<string[]>([]);
  const [selectedEquipments, setSelectedEquipments] = useState<string[]>([
    t("form.all")
  ]);
  const [makeSelected, setMakeSelected] = useState<VehiclesMake>({
    name: "",
    isDivider: false
  });
  const [colorSelected, setColorSelected] = useState<VehicleColor>({
    color: ""
  });
  const [specificModels, setSpecificModels] = useState<string[]>([]);
  const [genericModels, setGenericModels] = useState<string[]>([]);
  const [specificModelSelected, setSpecificModelSelected] =
    useState<string>("");
  const [genericModelSelected, setGenericModelSelected] = useState<string>("");
  const [isSpecificModelLoading, setSpecificModelLoading] =
    useState<boolean>(false);
  const [isGenericModelLoading, setGenericModelLoading] =
    useState<boolean>(false);

  const [selectedMonitoredReasons, setSelectedMonitoredReasons] = useState<
    AutocompleteOptions[]
  >([
    {
      value: "all",
      label: t("form.all")
    }
  ]);
  const [selectedVehicleClass, setSelectedVehicleClass] = useState<
    AutocompleteOptions[]
  >([
    {
      value: "all",
      label: t("form.all")
    }
  ]);
  const [classOptions, setClassOptions] = useState<AutocompleteOptions[]>([
    {
      value: "truck",
      label: t("MonitoredVehiclesDashboardPage.truck")
    },
    {
      value: "car",
      label: t("MonitoredVehiclesDashboardPage.car")
    },
    {
      value: "unknown",
      label: t("MonitoredVehiclesDashboardPage.unknown")
    },
    {
      value: "motorcycle",
      label: t("MonitoredVehiclesDashboardPage.motorcycle")
    },
    {
      value: "bus",
      label: t("MonitoredVehiclesDashboardPage.bus")
    }
  ]);

  const [reasonOptions, setReasonsOptions] = useState<AutocompleteOptions[]>([
    {
      value: "search_and_seizure",
      label: t("MonitoredVehiclesDashboardPage.search_and_seizure")
    },
    {
      value: "intelligence",
      label: t("MonitoredVehiclesDashboardPage.intelligence")
    },
    {
      value: "theft_and_robbery",
      label: t("MonitoredVehiclesDashboardPage.theft_and_robbery")
    },
    {
      value: "documentation",
      label: t("MonitoredVehiclesDashboardPage.documentation")
    },
    {
      value: "automatic_monitoring",
      label: t("MonitoredVehiclesDashboardPage.automatic_monitoring")
    }
  ]);

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    setIsLoading(true);
    try {
      const locationsResponse = await LocationAPI.listAll(
        sessionUser.customer_id
      );

      setLocations([
        t("form.all"),
        ...locationsResponse.data.map(location => location.location_name)
      ]);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  }, [sessionUser]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const updateAutoCompleteLabel = (options: AutocompleteOptions) => ({
    ...options,
    label: t(`MonitoredVehiclesDashboardPage.${options.value}`)
  });

  useEffect(() => {
    if (open) {
      const newClasses: AutocompleteOptions[] = classOptions.map(
        updateAutoCompleteLabel
      );
      const newReasons: AutocompleteOptions[] = reasonOptions.map(
        updateAutoCompleteLabel
      );
      setClassOptions([
        {
          value: "all",
          label: t("form.all")
        },
        ...newClasses
      ]);
      setReasonsOptions([
        {
          value: "all",
          label: t("form.all")
        },
        ...newReasons
      ]);
    }
  }, [language, open]);

  const clear = () => {
    reset(defaultValues);
    setValue("plate", "");
    setTodayCheckbox(false);
    setMonthCheckbox(false);
    const d = setMinutes(setHours(new Date().setDate(1), 0), 0);
    setValue("startDate", d);
    setValue("startTime", startOfDay(defaultDate));
    setValue("endDate", new Date());
    setValue("endTime", endOfDay(defaultDate));
    setMakeSelected({ name: "", isDivider: false });
    setGenericModelSelected("");
    setSpecificModelSelected("");
    setColorSelected({ color: "" });
    setSelectedEquipments([t("form.all")]);
    setSelectedMonitoredReasons([
      {
        value: "all",
        label: t("form.all")
      }
    ]);
    setSelectedVehicleClass([
      {
        value: "all",
        label: t("form.all")
      }
    ]);
  };

  const changeOptionsValue = (objects: AutocompleteOptions[], type: string) => {
    if (type === "reason") {
      setSelectedMonitoredReasons(objects);
    } else {
      setSelectedVehicleClass(objects);
    }
  };

  const setSpecificModelOptions = async () => {
    if (!sessionUser?.["customer_id"]) return;
    setSpecificModelLoading(true);
    try {
      const response = await ToolsAPI.getSpecificModels(makeSelected.name);
      setSpecificModels(response.model.map(item => item.name) || []);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setSpecificModelLoading(false);
    }
  };

  const setGenericModelOptions = async () => {
    if (!sessionUser?.["customer_id"]) return;
    setGenericModelLoading(true);
    try {
      const response = await ToolsAPI.getGenericModels(makeSelected.name);
      setGenericModels(response.model.map(item => item.name) || []);
    } catch (error: unknown) {
      const err = error as AxiosError;
      if (err.response?.data.error.code === 4054) {
        setGenericModels([]);
      } else {
        errorHandler({ error });
      }
    } finally {
      setGenericModelLoading(false);
    }
  };

  useEffect(() => {
    if (makeSelected.name === "") {
      setSpecificModelSelected("");
      setGenericModelSelected("");
    } else {
      setSpecificModelSelected("");
      setSpecificModelOptions();
      setGenericModelSelected("");
      setGenericModelOptions();
    }
  }, [makeSelected]);

  const handleTodayCheckbox = (newValue: boolean) => {
    if (monthCheckbox) {
      setMonthCheckbox(false);
    }

    setTodayCheckbox(newValue);

    if (newValue) {
      const d = new Date();
      setValue("startDate", startOfDay(d));
      setValue("endDate", d);
      setValue("startTime", startOfDay(d));
      setValue("endTime", d);
    }
  };

  const handleMonthCheckbox = (newValue: boolean) => {
    if (todayCheckbox) {
      setTodayCheckbox(false);
    }

    setMonthCheckbox(newValue);

    if (newValue) {
      setValue("startDate", setMinutes(setHours(new Date().setDate(1), 0), 0));
      setValue("endDate", new Date());
      setValue("startTime", setMinutes(setHours(new Date().setDate(1), 0), 0));
      setValue("endTime", new Date());
    }
  };

  const onSubmit = async (data: FormDashboardFilter) => {
    const newData = { ...data };

    newData.startDate = new Date(newData.startDate);
    newData.endDate = new Date(newData.endDate);
    newData.startTime = new Date(newData.startTime);
    newData.endTime = new Date(newData.endTime);
    newData.plate = newData.plate === "" ? undefined : newData.plate;

    newData.make = makeSelected.name;

    if (specificModelSelected === "" && genericModelSelected !== "") {
      newData.model = genericModelSelected;
    } else if (genericModelSelected === "" && specificModelSelected !== "") {
      newData.model = specificModelSelected;
    } else {
      newData.model = undefined;
    }

    newData.color = colorSelected.color;

    if (
      selectedEquipments.includes(t("form.all")) ||
      selectedEquipments.length === 0
    ) {
      newData.equipments = undefined;
    } else {
      newData.equipments = selectedEquipments;
    }

    const monitoredReasonsValues: string[] = selectedMonitoredReasons.map(
      option => option.value
    );

    if (
      monitoredReasonsValues.includes("all") ||
      monitoredReasonsValues.length === 0
    ) {
      newData.monitoredReasons = undefined;
    } else {
      newData.monitoredReasons = monitoredReasonsValues;
    }

    const vehicleClassValues: string[] = selectedVehicleClass.map(
      option => option.value
    );

    if (vehicleClassValues.includes("all") || vehicleClassValues.length === 0) {
      newData.vehicleClass = undefined;
    } else {
      newData.vehicleClass = vehicleClassValues;
    }

    setFilter(newData);
    setOpen(false);
  };

  useEffect(() => {
    if (
      getValues("startDate") !== "" &&
      getValues("endDate") !== "" &&
      getValues("startTime") !== "" &&
      getValues("endTime") !== ""
    ) {
      setTodayCheckbox(true);
    }
  }, []);

  return (
    <Drawer
      open={open}
      setOpen={setOpen}
      title={t("MonitoredVehiclesDashboardPage.filter")}
    >
      <Divider sx={{ mb: 2 }} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={8}>
              <Controller
                name="startDate"
                rules={{
                  required: t("form.requiredField").toString()
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    required
                    disabled={isLoading || todayCheckbox || monthCheckbox}
                    field={{ ...field }}
                    fieldState={fieldState}
                    label={t("MonitoredVehiclesDashboardPage.startDateTime")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="startTime"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <TimePickerField
                    required
                    gutter={false}
                    disabled={isLoading || todayCheckbox || monthCheckbox}
                    field={{ ...field }}
                    fieldState={fieldState}
                    sx={{ mt: 3 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="endDate"
                rules={{
                  required: t("form.requiredField").toString()
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    required
                    disabled={isLoading || todayCheckbox || monthCheckbox}
                    field={{ ...field }}
                    fieldState={fieldState}
                    label={t("MonitoredVehiclesDashboardPage.endDateTime")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="endTime"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <TimePickerField
                    required
                    gutter={false}
                    disabled={isLoading || todayCheckbox || monthCheckbox}
                    field={{ ...field }}
                    fieldState={fieldState}
                    sx={{ mt: 3 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    color="secondary"
                    checked={todayCheckbox}
                    onChange={(_event, newValue) => {
                      handleTodayCheckbox(newValue);
                    }}
                  />
                }
                label={t("MonitoredVehiclesDashboardPage.today").toString()}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    color="secondary"
                    checked={monthCheckbox}
                    onChange={(_event, newValue) => {
                      handleMonthCheckbox(newValue);
                    }}
                  />
                }
                label={t("MonitoredVehiclesDashboardPage.month").toString()}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider>
                <Typography variant="h5" color="primary.light">
                  {t("DefaultPageLayout.plateSearch")}
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="plate"
                control={control}
                render={({ field, fieldState }) => (
                  <InputField
                    gutter={false}
                    label={t("MonitoredVehiclesDashboardPage.plate")}
                    customProps={{
                      disabled: isLoading,
                      placeholder: t(
                        "MonitoredVehiclesDashboardPage.plateHint"
                      ),
                      inputProps: {
                        maxLength: 7
                      }
                    }}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
              <Placeholder variant="caption" align="left">
                {t("DefaultPageLayout.tooltipPlate")}
              </Placeholder>
            </Grid>
            <Grid item xs={12}>
              <Divider>
                <Typography variant="h5" color="primary.light">
                  {t("DefaultPageLayout.characteristicsSearch")}
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t("IncidentReportPage.make")}</FormLabel>
              <Autocomplete
                disablePortal
                size="small"
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={makes}
                noOptionsText={t("form.noOptions")}
                getOptionLabel={option => option.name}
                value={makeSelected}
                onChange={(event, value) => {
                  event.preventDefault();
                  setMakeSelected({
                    name: value?.name || "",
                    isDivider: false
                  });
                }}
                renderInput={params => (
                  <div style={{ position: "relative" }}>
                    <TextField
                      {...params}
                      placeholder={t("form.startTyping")}
                    />
                  </div>
                )}
                renderOption={(props, value) => (
                  <>
                    <li {...props}>{value.name}</li>
                    {value.isDivider === true ? <Divider /> : null}
                  </>
                )}
              />
              <Typography align="left" variant="caption" color="primary.light">
                {t("DefaultPageLayout.tooltipModel")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t("IncidentReportPage.genericModel")}</FormLabel>
              <Autocomplete
                disablePortal
                size="small"
                disabled={
                  makeSelected.name === "" ||
                  isGenericModelLoading ||
                  specificModelSelected !== ""
                }
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={genericModels}
                noOptionsText={t("form.noOptions")}
                getOptionLabel={option => option}
                value={genericModelSelected}
                onChange={(event, value) => {
                  event.preventDefault();
                  setGenericModelSelected(value || "");
                }}
                renderInput={params => (
                  <div style={{ position: "relative" }}>
                    <TextField
                      {...params}
                      placeholder={t("form.startTyping")}
                    />
                    {isGenericModelLoading && (
                      <CircularProgress
                        color="secondary"
                        size={24}
                        sx={{
                          position: "absolute",
                          top: "10px",
                          right: "42px"
                        }}
                      />
                    )}
                  </div>
                )}
              />
              <Divider>
                <Typography variant="subtitle1" color="primary.light">
                  {t("DefaultPageLayout.or")}
                </Typography>
              </Divider>
              <FormLabel>{t("IncidentReportPage.specificModel")}</FormLabel>
              <Autocomplete
                disablePortal
                size="small"
                disabled={
                  makeSelected.name === "" ||
                  isSpecificModelLoading ||
                  genericModelSelected !== ""
                }
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={specificModels}
                noOptionsText={t("form.noOptions")}
                getOptionLabel={option => option}
                value={specificModelSelected}
                onChange={(event, value) => {
                  event.preventDefault();
                  setSpecificModelSelected(value || "");
                }}
                renderInput={params => (
                  <div style={{ position: "relative" }}>
                    <TextField
                      {...params}
                      placeholder={t("form.startTyping")}
                    />
                    {isSpecificModelLoading && (
                      <CircularProgress
                        color="secondary"
                        size={24}
                        sx={{
                          position: "absolute",
                          top: "10px",
                          right: "42px"
                        }}
                      />
                    )}
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t("IncidentReportPage.color")}</FormLabel>
              <Autocomplete
                disablePortal
                size="small"
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={vehicleColors}
                noOptionsText={t("form.noOptions")}
                getOptionLabel={option => option.color}
                value={colorSelected}
                onChange={(event, value) => {
                  event.preventDefault();
                  setColorSelected({ color: value?.color || "" });
                }}
                renderInput={params => (
                  <div style={{ position: "relative" }}>
                    <TextField
                      {...params}
                      placeholder={t("form.startTyping")}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>
                {t("MonitoredVehiclesDashboardPage.vehicleClass")}
              </FormLabel>
              <Autocomplete
                multiple
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={orderBy(classOptions, ["", "label"], [false, "asc"])}
                getOptionLabel={option => option.label}
                filterSelectedOptions
                onChange={(event, newValues) => {
                  event.preventDefault();
                  const noIsAll = !newValues.some(
                    ({ value }) => value === "all"
                  );
                  if (
                    selectedVehicleClass.some(({ value }) => value === "all") &&
                    selectedVehicleClass.length === 1
                  ) {
                    const idx = newValues.findIndex(
                      item => item.value === "all"
                    );
                    newValues.splice(idx, 1);
                    changeOptionsValue(newValues, "class");
                  } else if (noIsAll) {
                    changeOptionsValue(newValues, "class");
                  } else {
                    changeOptionsValue(
                      [
                        {
                          value: "all",
                          label: t("form.all")
                        }
                      ],
                      "class"
                    );
                  }
                }}
                noOptionsText={t("form.noOptions")}
                value={selectedVehicleClass}
                size="small"
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={t(
                      "MonitoredVehiclesDashboardPage.vehicleClassHint"
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>
                {t("MonitoredVehiclesDashboardPage.monitoredReason")}
              </FormLabel>
              <Autocomplete
                multiple
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={orderBy(reasonOptions, ["", "label"], [false, "asc"])}
                getOptionLabel={option => option.label}
                filterSelectedOptions
                onChange={(event, newValues) => {
                  event.preventDefault();
                  const noIsAll = !newValues.some(
                    ({ value }) => value === "all"
                  );
                  if (
                    selectedMonitoredReasons.some(
                      ({ value }) => value === "all"
                    ) &&
                    selectedMonitoredReasons.length === 1
                  ) {
                    const idx = newValues.findIndex(
                      item => item.value === "all"
                    );
                    newValues.splice(idx, 1);
                    changeOptionsValue(newValues, "reason");
                  } else if (noIsAll) {
                    changeOptionsValue(newValues, "reason");
                  } else {
                    changeOptionsValue(
                      [
                        {
                          value: "all",
                          label: t("form.all")
                        }
                      ],
                      "reason"
                    );
                  }
                }}
                value={selectedMonitoredReasons}
                renderOption={(_, option) => (
                  <Typography key={option.value}>{option.label}</Typography>
                )}
                noOptionsText={t("form.noOptions")}
                size="small"
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={t(
                      "MonitoredVehiclesDashboardPage.monitoredReasonHint"
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>
                {t("MonitoredVehiclesDashboardPage.equipments")}
              </FormLabel>
              <Autocomplete
                multiple
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={locations}
                getOptionLabel={option => option}
                filterSelectedOptions
                onChange={(event, newValues) => {
                  event.preventDefault();
                  const noIsAll = !newValues.includes(t("form.all"));
                  if (
                    selectedEquipments.includes(t("form.all")) &&
                    selectedEquipments.length === 1
                  ) {
                    const idx = newValues.indexOf(t("form.all"));
                    newValues.splice(idx, 1);
                    setSelectedEquipments(newValues);
                  } else if (noIsAll) {
                    setSelectedEquipments(newValues);
                  } else {
                    setSelectedEquipments([t("form.all")]);
                  }
                }}
                noOptionsText={t("form.noOptions")}
                value={selectedEquipments}
                size="small"
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={t(
                      "MonitoredVehiclesDashboardPage.equipmentHint"
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Content>
        <Footer>
          <Button
            customProps={{
              color: "primary",
              variant: "outlined",
              onClick: () => clear()
            }}
          >
            {t("action.clear")}
          </Button>
          <Button
            customProps={{
              disabled: !formState.isValid || isLoading,
              type: "submit"
            }}
          >
            {t("action.filter")}
          </Button>
        </Footer>
      </form>
    </Drawer>
  );
};

export default MonitoredVehiclesDashboardFilter;
