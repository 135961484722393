import { AxiosResponse } from "axios";
import useAxios from "hooks/useAxios";
import APIEndpoints from "enums/APIEndpoints";

export type ConvoyReport = {
  id: string;
  ["date_capture"]: string;
  gap: string;
  equipment: string;
  cc?: string;
  ["classifier_list"]?: string;
  camera: string;
  plate: string;
  ["vehicle_make"]: string;
  ["vehicle_model"]: string;
  ["vehicle_color"]: string;
  ["vehicle_class"]: string;
  ["vehicle_year"]: string;
  image_link: string;
  ["ocr_total_time"]: number;
  ["plate_color"]: number;
  ["plate_coordinate"]: string;
};

export type ConvoyReportPaginated = {
  registers: {
    items: ConvoyReport[];
    page: number;
    ["total_items"]: number;
    ["total_pages"]: number;
    referential: ConvoyReport;
  };
};

type ListParams = {
  ["customer_id"]: string;
  plate: string;
  ["referential_date"]: string;
  equipment: string;
  time: number;
  page?: number;
  ["page_size"]?: number;
};

type DownloadParams = {
  ["customer_id"]: string;
  plate: string;
  ["referential_date"]: string;
  equipment: string;
  time: number;
  language: string;
};

type UseConvoyReportAPIType = {
  list: (param: ListParams) => Promise<ConvoyReportPaginated>;
  downloadCsv: (param: DownloadParams) => Promise<string>;
  downloadPdf: (param: DownloadParams) => Promise<string>;
};

const useConvoyReportAPI = (): UseConvoyReportAPIType => {
  const API = useAxios();

  const list = async (filter: ListParams): Promise<ConvoyReportPaginated> => {
    const response: AxiosResponse<ConvoyReportPaginated> =
      await API.instance.post(APIEndpoints.CONVOY_REPORT_LIST, filter);
    return response.data;
  };

  const downloadCsv = async (filter: DownloadParams): Promise<string> => {
    const response: AxiosResponse<string> = await API.instance.post(
      APIEndpoints.CONVOY_REPORT_DOWNLOAD,
      {
        ...filter,
        type: "csv"
      },
      {
        responseType: "blob"
      }
    );
    return response.data;
  };

  const downloadPdf = async (filter: DownloadParams): Promise<string> => {
    const response: AxiosResponse<string> = await API.instance.post(
      APIEndpoints.CONVOY_REPORT_DOWNLOAD,
      {
        ...filter,
        type: "pdf"
      }
    );
    return response.data;
  };

  return {
    list,
    downloadCsv,
    downloadPdf
  };
};

export default useConvoyReportAPI;
