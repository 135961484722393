import { Box, useTheme, useMediaQuery, Typography } from "@mui/material";
import Pages from "enums/Pages";
import useCameraDashboardAPI, {
  CameraDashboardResponse
} from "api/CameraDashboardAPI";
import useCameraAPI from "api/CameraAPI";
import fileDownload from "js-file-download";
import { subDays } from "date-fns";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import MenuButton from "components/MenuButton";
import { Download, Sliders } from "react-feather";
import { useLocale } from "contexts/LocaleContext";
import CameraDashboardFilter, {
  FormCameraDashboardFilter
} from "pages/CameraDashboard/CameraDashboardFilter";
import InnerPageLayout from "layouts/InnerPageLayout";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import PageSection from "components/PageSection/PageSection";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { usePageLocation } from "contexts/PageLocationContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { dateToString } from "utils/DateFunctions";
import CameraDashboardHeatmap, { HeatmapData } from "./CameraDashboardHeatmap";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";

export type CacheDatas = {
  filter: {
    startDate: string | Date;
    endDate: string | Date;
    monthDate: string | Date;
    camera: string;
    grouping: string;
  };
  camData: CameraDashboardResponse;
};

const CameraDashboardPage: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const CameraAPI = useCameraAPI();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const CameraDashboardAPI = useCameraDashboardAPI();
  const [isDownloadingCsv, setDownloadingCsv] = useState<boolean>(false);
  const [isDownloadingPdf, setDownloadingPdf] = useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { setPageTitle, setLocation } = usePageLocation();
  const [filterData, setFilterData] = useState<FormCameraDashboardFilter>({
    startDate: subDays(new Date(), 7),
    endDate: new Date(),
    monthDate: new Date(),
    camera: "",
    grouping: "week"
  });
  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();
  const [camAvailability, setCamAvailability] = useState<HeatmapData[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const { language } = useLocale();
  const [cameras, setCameras] = useState<string[]>([]);
  const [isCacheRequest, setIsCacheRequest] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (sessionUser) {
        const customerId = sessionUser["customer_id"];
        try {
          const cameraResponse = await CameraAPI.listAll({ customerId });
          const camerasNames = cameraResponse.data.map(
            camera => camera["camera_name"]
          );
          setCameras(camerasNames);
          setIsFilterOpen(true);
        } catch (error) {
          errorHandler({ error });
        }
      }
    })();
  }, []);

  useEffect(() => {
    setPageTitle(t("windowTitle.cameraDashboard"));
    setLocation([
      {
        label: t("DefaultPageLayout.cameraDashboard"),
        page: Pages.CAMERA_DASHBOARD
      }
    ]);
  }, [t, Pages]);

  const requestData = useCallback(
    async (filter: FormCameraDashboardFilter, isCache: boolean) => {
      if (!sessionUser?.["customer_id"]) return;
      setIsFetching(true);
      try {
        if (!isCache) {
          const requestPayload =
            filter.grouping === "week"
              ? {
                  ["customer_id"]: sessionUser.customer_id,
                  ["camera"]: filter.camera,
                  ["initial_date"]: dateToString(filter.startDate as Date),
                  ["final_date"]: dateToString(filter.endDate as Date),
                  ["grouping"]: filter.grouping
                }
              : {
                  ["customer_id"]: sessionUser.customer_id,
                  ["camera"]: filter.camera,
                  year: (filter.monthDate as Date).getFullYear(),
                  month: (filter.monthDate as Date).getMonth() + 1,
                  ["grouping"]: filter.grouping
                };

          const [cameraResponse] = await Promise.all([
            CameraDashboardAPI.get(requestPayload)
          ]);

          let cachedDataArray: CacheDatas[] = [];
          const cachedData = localStorage.getItem("camera_dashboard");
          if (cachedData) {
            cachedDataArray = JSON.parse(cachedData);
            if (
              cachedDataArray.findIndex(
                item =>
                  item.filter.camera === filter.camera &&
                  dateToString(new Date(item.filter.startDate)) ===
                    dateToString(new Date(filter.startDate)) &&
                  dateToString(new Date(item.filter.endDate)) ===
                    dateToString(new Date(filter.endDate)) &&
                  dateToString(new Date(item.filter.monthDate)) ===
                    dateToString(new Date(filter.monthDate))
              ) === -1
            ) {
              updateCache(cachedDataArray, filter, cameraResponse);
            }
          } else {
            cachedDataArray.push({
              filter: {
                camera: filter.camera,
                startDate: dateToString(filter.startDate as Date),
                endDate: dateToString(filter.endDate as Date),
                monthDate: filter.monthDate,
                grouping: filter.grouping
              },
              camData: cameraResponse
            });
            localStorage.setItem(
              "camera_dashboard",
              JSON.stringify(cachedDataArray)
            );
          }
          setCamAvailability(prepareData(cameraResponse, filter));
        }
        setIsCacheRequest(false);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsFetching(false);
      }
    },
    [sessionUser]
  );

  const prepareData = (
    response: CameraDashboardResponse,
    filter: FormCameraDashboardFilter
  ): HeatmapData[] => {
    const monthKeys = Object.keys(response);
    const monthvalues = Object.values(response);
    const monthChartData: HeatmapData[] = [];
    const startDate = new Date(filter.startDate);

    monthvalues.forEach((value, index) => {
      monthKeys.forEach(key => {
        if ((index + 1).toString() === key) {
          const currentDate = new Date(startDate);
          currentDate.setDate(startDate.getDate() + parseInt(key, 10) - 1);
          const formattedDate = currentDate.toLocaleDateString(language, {
            day: "2-digit",
            month: "2-digit"
          });

          monthChartData.push({
            name: `${formattedDate}`,
            data: value.map(cData => ({
              x: `${cData.hour.toString()}h`,
              y: Number(cData.not_null)
            }))
          });
        }
      });
    });

    return monthChartData;
  };

  const setCacheData = (cacheData: CacheDatas) => {
    setFilterData({
      startDate: cacheData.filter.startDate,
      endDate: cacheData.filter.endDate,
      monthDate: cacheData.filter.monthDate,
      camera: cacheData.filter.camera,
      grouping: cacheData.filter.grouping
    });
    setIsCacheRequest(true);
    if (cacheData.camData && cacheData.camData[1].length > 0) {
      setIsCacheRequest(true);
      setCamAvailability(prepareData(cacheData.camData, cacheData.filter));
    }
  };

  const updateCache = (
    cacheArray: CacheDatas[],
    filter: FormCameraDashboardFilter,
    camData: CameraDashboardResponse
  ) => {
    const newCache = [...cacheArray];
    if (newCache.length < 5) {
      newCache.push({
        filter: {
          camera: filter.camera,
          startDate: dateToString(filter.startDate as Date),
          endDate: dateToString(filter.endDate as Date),
          monthDate: filter.monthDate,
          grouping: filter.grouping
        },
        camData
      });
      localStorage.setItem("camera_dashboard", JSON.stringify(newCache));
    } else if (newCache.length === 5) {
      newCache[0] = {
        filter: {
          camera: filter.camera,
          startDate: dateToString(filter.startDate as Date),
          endDate: dateToString(filter.endDate as Date),
          monthDate: filter.monthDate,
          grouping: filter.grouping
        },
        camData
      };
      localStorage.setItem("camera_dashboard", JSON.stringify(newCache));
    }
  };

  const setTitle = (): ReactNode | string => {
    const title = t("DefaultPageLayout.cameraDashboard");
    return filterData.camera.length > 0 &&
      filterData.startDate &&
      filterData.endDate ? (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h3">{title}</Typography>
        <br />
        <Typography variant="h6">{`${filterData.camera}: (${dateToString(
          new Date(filterData.startDate)
        )} - ${dateToString(new Date(filterData.endDate))}) `}</Typography>
      </div>
    ) : (
      title
    );
  };

  const downloadCsv = async () => {
    if (!filterData || isDownloadingCsv || !sessionUser?.["customer_id"])
      return;
    const customerId = sessionUser["customer_id"];
    try {
      setDownloadingCsv(true);
      const response = await CameraDashboardAPI.downloadCsv({
        camera: filterData.camera,
        ["customer_id"]: customerId,
        grouping: filterData.grouping,
        language: language.split("-")[0],
        year: filterData.monthDate
          ? new Date(filterData.monthDate).getFullYear()
          : undefined,
        month: filterData.monthDate
          ? new Date(filterData.monthDate).getMonth() + 1
          : undefined,
        ["initial_date"]: filterData.startDate
          ? dateToString(new Date(filterData.startDate))
          : undefined,
        ["final_date"]: filterData.endDate
          ? dateToString(new Date(filterData.endDate))
          : undefined
      });
      fileDownload(response, "Camera_Availability.csv", "text/csv");
    } catch (error) {
      errorHandler({ error });
    } finally {
      setDownloadingCsv(false);
    }
  };

  const forceDownloadPdf = (pdf: string) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "Camera_Availability.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadPdf = async () => {
    if (!filterData || isDownloadingPdf || !sessionUser?.["customer_id"])
      return;
    const customerId = sessionUser["customer_id"];
    try {
      setDownloadingPdf(true);
      const response = await CameraDashboardAPI.downloadPdf({
        camera: filterData.camera,
        ["customer_id"]: customerId,
        grouping: filterData.grouping,
        language: language.split("-")[0],
        year: filterData.monthDate
          ? new Date(filterData.monthDate).getFullYear()
          : undefined,
        month: filterData.monthDate
          ? new Date(filterData.monthDate).getMonth() + 1
          : undefined,
        ["initial_date"]: filterData.startDate
          ? dateToString(new Date(filterData.startDate))
          : undefined,
        ["final_date"]: filterData.endDate
          ? dateToString(new Date(filterData.endDate))
          : undefined
      });
      forceDownloadPdf(response);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setDownloadingPdf(false);
    }
  };

  useEffect(() => {
    if (filterData.camera !== "") {
      requestData(filterData, isCacheRequest);
    }
  }, [requestData, filterData]);

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={setTitle()}
          isLoading={isFetching}
          actions={
            <>
              <MenuButton
                id="download-button"
                icon={<Download />}
                label={t("action.export")}
                actions={[
                  {
                    key: "downloadCsv",
                    isLoading: isDownloadingCsv,
                    label: t("CaptureReportPage.exportCsv"),
                    onClick: () => downloadCsv()
                  },
                  {
                    key: "downloadPdf",
                    isLoading: isDownloadingPdf,
                    label: t("CaptureReportPage.exportPdf"),
                    onClick: () => downloadPdf()
                  }
                ]}
              />
              <PageSectionHeaderAction
                variant="outlined"
                color="primary"
                startIcon={<Sliders />}
                onClick={() => setIsFilterOpen(true)}
              >
                {t("action.filter")}
              </PageSectionHeaderAction>
            </>
          }
        >
          <CameraDashboardFilter
            open={isFilterOpen}
            setOpen={setIsFilterOpen}
            filter={filterData}
            setFilter={setFilterData}
            isLoading={isFetching}
            cameras={cameras}
          />
          <Box>
            <CameraDashboardHeatmap
              heatmapData={camAvailability}
              isFetching={isFetching}
              options={{
                chart: {
                  type: "heatmap",
                  toolbar: {
                    show: true,
                    tools: {
                      download: false,
                      selection: false,
                      zoom: false,
                      zoomin: false,
                      zoomout: false,
                      pan: false,
                      reset: false
                    }
                  }
                },
                plotOptions: {
                  heatmap: {
                    enableShades: false,
                    colorScale: {
                      ranges: [
                        {
                          from: 0,
                          to: 0,
                          name: t("CameraDashboardPage.heatmapTooltipHigh"),
                          color: "#FF0000"
                        },
                        {
                          from: 1,
                          to: Infinity,
                          name: t("CameraDashboardPage.heatmapTooltipLow"),
                          color: "#00A100"
                        }
                      ]
                    }
                  }
                },
                xaxis: {
                  labels: {
                    show: true,
                    rotate: -45,
                    rotateAlways: true
                  }
                },
                yaxis: {
                  labels: {
                    show: !isMobile
                  }
                }
              }}
              setCacheData={cacheData => setCacheData(cacheData)}
            />
          </Box>
        </PageSection>
      </InnerPageLayout>
    </DefaultPageLayout>
  );
};

export default CameraDashboardPage;
