import { AxiosResponse } from "axios";
import APIEndpoints from "enums/APIEndpoints";
import useAxios from "hooks/useAxios";

export type CaptureReport = {
  id: string;
  ["date_capture"]: string;
  camera: string;
  side: string;
  direction: string;
  street: string;
  number: string;
  city: string;
  state: string;
  zip_code: string;
  ["serial_number"]: string;
  equipment: string;
  plate: string;
  image_link: string;
  ["vehicle_make"]: string;
  ["vehicle_model"]: string;
  ["vehicle_color"]: string;
  ["vehicle_class"]: string;
  ["vehicle_year"]: string;
  ["latitude"]: string;
  ["longitude"]: string;
  ["ocr_total_time"]: number;
  ["plate_color"]: number;
  ["plate_coordinate"]: string;
  ["classifier_list"]: string;
};

export type CaptureReportPaginated = {
  registers: {
    items: CaptureReport[];
    page: number;
    ["total_items"]: number;
    ["total_pages"]: number;
  };
};

export type ListCaptureReportParams = {
  ["customer_id"]: string;
  ["page_size"]?: number;
  page?: number;
  ["basic_filter"]?: {
    ["initial_date"]?: string;
    ["final_date"]?: string;
    plate?: string;
    ["id_capture"]?: string;
    equipments?: string[];
    cameras?: string[];
    vehicle_classes?: string[];
    ["vehicle_make"]?: string;
    ["vehicle_model"]?: string;
    ["vehicle_color"]?: string;
    ["number_occurrences"]?: number[];
  };
};

export type DownloadCaptureReportParams = {
  ["customer_id"]: string;
  language: string;
  ["columns_selected"]: string[];
  ["basic_filter"]?: {
    ["initial_date"]?: string;
    ["final_date"]?: string;
    plate?: string;
    ["id_capture"]?: string;
    equipments?: string[];
    cameras?: string[];
    ["vehicle_make"]?: string;
    ["vehicle_model"]?: string;
    ["vehicle_color"]?: string;
    ["number_occurrences"]?: number[];
  };
};

export type DownloadCaptureDetailsParams = {
  ["customer_id"]: string;
  ["id_register"]: string;
  language: string;
};

export type VehiclesMake = {
  name: string;
  isDivider: boolean;
};

export type DelayInfo = {
  delayRegister: string;
  processRegister: string;
  delayImage: string;
  processImage: string;
};

type UseCaptureReportAPIType = {
  list: (param: ListCaptureReportParams) => Promise<CaptureReportPaginated>;
  downloadCsv: (param: DownloadCaptureReportParams) => Promise<string>;
  downloadPdf: (param: DownloadCaptureReportParams) => Promise<string>;
  downloadDetailsPdf: (param: DownloadCaptureDetailsParams) => Promise<string>;
  getDelay: (customerId: string, registerId: string) => Promise<DelayInfo>;
};

const useCaptureReportAPI = (): UseCaptureReportAPIType => {
  const API = useAxios();

  const list = async (
    filter: ListCaptureReportParams
  ): Promise<CaptureReportPaginated> => {
    const response: AxiosResponse<CaptureReportPaginated> =
      await API.instance.post(APIEndpoints.CAPTURE_REPORT_LIST, filter);
    return response.data;
  };

  const downloadCsv = async (
    filter: DownloadCaptureReportParams
  ): Promise<string> => {
    const response: AxiosResponse<string> = await API.instance.post(
      APIEndpoints.CAPTURE_REPORT_DOWNLOAD,
      {
        ...filter,
        type: "csv",
        timezone: -new Date().getTimezoneOffset() / 60
      },
      {
        responseType: "blob"
      }
    );
    return response.data;
  };

  const downloadPdf = async (
    filter: DownloadCaptureReportParams
  ): Promise<string> => {
    const response: AxiosResponse<string> = await API.instance.post(
      APIEndpoints.CAPTURE_REPORT_DOWNLOAD,
      {
        ...filter,
        type: "pdf",
        timezone: -new Date().getTimezoneOffset() / 60
      }
    );
    return response.data;
  };

  const downloadDetailsPdf = async (
    filter: DownloadCaptureDetailsParams
  ): Promise<string> => {
    const response: AxiosResponse<string> = await API.instance.post(
      APIEndpoints.REGISTER_DETAILS_DOWNLOAD,
      filter
    );
    return response.data;
  };

  const getDelay = async (
    customerId: string,
    registerId: string
  ): Promise<DelayInfo> => {
    const response: AxiosResponse<DelayInfo> = await API.instance.get(
      `${APIEndpoints.REGISTER_DELAY}?customer_id=${customerId}&register_id=${registerId}`
    );
    return response.data;
  };

  return {
    list,
    downloadCsv,
    downloadPdf,
    downloadDetailsPdf,
    getDelay
  };
};

export default useCaptureReportAPI;
